<style>

.power-up{
  width: 100px;
  border: 1px #777 solid;
  height: 20px;
}
.power-meter{
  height: 20px;
  background-color: #777;
}

</style>

<div class="power-up">
  <div class="power-meter" style="width: {laserPowerUp}px;"></div>
</div>

<script>

export let laserPowerUp = 0;

</script>