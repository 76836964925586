<style>

#canvas{
	touch-action: none;
	-webkit-touch-action: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.container{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 32px;
}

</style>

<svelte:window on:resize="{drawResize}" 
  on:keydown="{keyDownHandler}"
  on:keyup="{keyUpHandler}"></svelte:window>
<!-- 
on:mousedown="{mousedown}" on:mousemove="{mousemove}" on:mouseup="{mouseup}" on:mouseout="{mouseout}"
on:touchstart="{touchstart}" on:touchmove="{touchmove}" on:touchend="{touchend}" -->

<div class="container" bind:clientWidth="{game.canvas.width}" bind:clientHeight="{game.canvas.height}">
  <canvas bind:this="{canvas}" width="{game.canvas.width}" height="{game.canvas.height}" tabindex="1"></canvas>
</div>

<script>

export let assets = [];
export let soundAssets = [];
export let score = 0;

// fps testing from https://stackoverflow.com/questions/8279729/calculate-fps-in-canvas-using-requestanimationframe/64141620#64141620
let fps = 1;
let times = [];
let fadeIn = [1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, ];

// console.log("fadeIn.length", fadeIn.length);
let fadeInFrame = 0;

let canvasError = false;
let canvas = null;
let foregroundColor = "#000";
let repaint = null;

let walkingRight = false;
let walkingLeft = false;

// specifies what direction diggy is currently facing, whether moving or not
let curDiggyDirection = "right";

// defines what frame we're drawing in "walkFrames"
// default to standing aka not walking
let curRightWalkFrame = 0;
// uses the asset name to define the actual frames to display in order
let walkRightFrames = [
  "diggy1-1",
  "diggy1-2",
  "diggy1-3",
  "diggy1-2",
  "diggy1-1",
  "diggy1-4",
  "diggy1-5",
  "diggy1-4",
]

let curLeftWalkFrame = 0;
let walkLeftFrames = [
  "diggy2-1",
  "diggy2-2",
  "diggy2-3",
  "diggy2-2",
  "diggy2-1",
  "diggy2-4",
  "diggy2-5",
  "diggy2-4",
]


// defines what frame we're drawing in "runFrames"
let curRightRunFrame = 0;
// uses the asset name to define the actual frames to display in order
let runRightFrames = [
  "diggy3-1",
  "diggy3-2",
  "diggy3-1",
  "diggy3-3",
  "diggy3-4",
  "diggy3-3",
]

// defines what frame we're drawing in "runFrames"
let curLeftRunFrame = 0;
// uses the asset name to define the actual frames to display in order
let runLeftFrames = [
  "diggy4-1",
  "diggy4-2",
  "diggy4-1",
  "diggy4-3",
  "diggy4-4",
  "diggy4-3",
]


let lastDiggy = "diggy1-1";

// is diggy running fast or normal walking?
let run = false;
let tweak = false;

// up, down, level = at the peak of the jump, no
let jumpFrame = "no";
// current height of jump - measure how high we are in a jump to impose limits
// is forgone if using a rocket jet pack
// it's assumed that if curJumpHeight is not zero, diggy is jumping
let curJumpHeight = 0;
let maxJumpHeight = 20;

// kills the keyboard repeat
let jumpOnceLatch = false;

let jumpRight = {
  up: "diggy10-1", 
  level: "diggy10-2", 
  down: "diggy10-3", 
}

let jumpLeft = {
  up: "diggy11-1", 
  level: "diggy11-2", 
  down: "diggy11-3", 
}

// for indicating falling off a block
let falling = false;

// make sure we're not doubling up on requestAnimationFrame 
let animating = false;

let game = {
  // canvas ctx
  ctx: null,
  
  // image assets
  assets: null,
  
  // sound assets
  sounds: null,
  
  // generated things
  
  // tunnels that main character has dug
  tunnels: [
    {x: 0, y: 0},
  ],
  

  // treasures
  treasures: {
    
    // p is points. o is opacity.
    bones: [
      {x: -150, y: 0, p: 10, o: 1},
      {x: -200, y: 0, p: 10, o: 1},
      {x: -250, y: 0, p: 10, o: 1},
      {x: -300, y: 0, p: 10, o: 1},
    ],
    goldBones: [
      {x: -100, y: 0, p: 50, o: 1},
    ],
    emeralds: [
      {x: -50, y: 0, p: 100, o: 1},
    ],
    rubies: [
      {x: 150, y: 0, p: 250, o: 1},
    ],
    gold: [
      {x: 200, y: 0, p: 500, o: 1},
    ],
  },
  
  
  props: {
    
    // helps calculate where to put props, relative to the doggy
    xOffset: 0,
    
    platforms: [
      // {x: 106 * 2, y: 0, w: 106 * 2, c: 0, c2: 1},
      // {x: 106 * 3, y: -64, w: 106 * 2, c: 1, c2: 2},
      // {x: 106 * 4, y: -64  * 2, w: 106 * 2, c: 2, c2: 3},
      // {x: 106 * 3, y: -64  * 3, w: 106 * 2, c: 3, c2: 4},
      // {x: 106 * 3, y: -64  * 5, w: 106 * 2, c: 4, c2: 5},
      // {x: 106 * 3, y: -64  * 9, w: 106 * 5, c: 5, c2: 6},
      // {x: 106 * 11, y: -64  * 9, w: 106 * 5, c: 0, c2: 7},
    ],
    
    // trees are automatically at layer 0
    trees: [{x: 0, s: 10, t: 1}],
    
    // clouds are automatically at layer 9
    // t = cloud type starting at 1
    clouds: [{x: 600, y: 100, s: 10, t: 2}],
    
    grass: [{x: 212, t: 1}],
    flowers: [{x: 200, t: 1}],
    
    // t: 0 = stairway to heaven
    // t: 1 = stairway to heck
    signs: [],
    
  },
  
  // character location within the map
  character: {
    x: 0,
    y: 0,
    
    // draw diggy at
    drawXAt: 0,
    drawYAt: 0,
    
    // collision detection points - used in tiles/Bounds.js for gems
    // point1: 0, // assumed
    point2: 35,
    point3: 71,
    point4: 106,
    
  },
  
  // absolute canvas dimensions
  canvas: {
    width: 100,
    height: 100,
    
    // on window size change, change these:
    lastWidth: 100,
    lastHeight: 100,
  },
  
  // center of the canvas
  center: {
    x: 0,
    y: 0,
  },
  
  // various nice to have pre calced screen attributes
  screen: {
    earthBeginsY: 0,
    tileSizeWidth: 128,
    tileSizeHeight: 64,
  },
  
  // the window to display, meaning anything outside the window is clipped
  // the window is divide into layers to help with parallax scrolling effects
  // we have ten layers.  layer zero is the closest, layer nine is furthest away
  // sx = startX, ex = endX, etc...
  window: [
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
    {sx: 0, ex: 0, sy: 0, ey: 0, },
  ],
  
  // how far have we generated the world?
  // each array element coorisponds with a window layer
  world: [
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
    {sx: 0, ex: 0, sy: 0, ey: 0,},
  ],
  
  // sunrise, day, sunset, night
  timeOfDay: "day",
  // timeOfDay: "night",
  
}



import Debug from "./tiles/Debug.js";
import Types from "./Types.js";
import Bounds from "./tiles/Bounds.js";

import Sky from "./tiles/Sky.js";
import Ground from "./tiles/Ground.js";
import Props from "./tiles/Props.js";

import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

import { onMount } from "svelte";
onMount(async () => {
  window.requestAnimationFrame(testFPS);
  
	if (canvas.getContext) {
    game.ctx = canvas.getContext("2d");
    game.assets = assets;
    game.sounds = soundAssets;
    console.log("game", game);
    
    game.canvas.lastWidth = JSON.parse( JSON.stringify(game.canvas.width) );
    game.canvas.lastHeight = JSON.parse( JSON.stringify(game.canvas.height) );
    
    
    initStorage();
    preCalc();
    calcWorldLayers()
    generateProps();
    
    // testing
    // Props.generatePlatforms(game, -500, 1000, 500, -1000);
    
    window.requestAnimationFrame(paint);
    
  }
});


function drawResize(e) {
	clearTimeout(repaint);
	repaint = setTimeout(function() {
    preCalc();
    calcWorldLayers()
    generateProps();
    window.requestAnimationFrame(paint);
	}, 100)
}

let start = 0;
function testFPS(timestamp) {
  // fps testing
  if (start == 0) start = timestamp;
  if (start < timestamp - 1200) {
    console.log(fps);

    // just a quick and dirty calculation
    // this helps calculates how far we move per frame
    frameTick = 30 / fps;
    frameTick = frameTick < 30 ? 30 : frameTick;
    console.log("frameTick:", frameTick);
    
    return;
  }
  
  while (times.length > 0 && times[0] <= timestamp - 1000) {
    times.shift();
  }
  timestamp = timestamp == undefined ? 0 : timestamp;
  times.push(timestamp);
  fps = times.length;
  window.requestAnimationFrame(testFPS);
}


function initStorage() {
  if (localStorage.map == null) {
    localStorage.setItem("map", JSON.stringify([]) );
  }
  if (localStorage.window == null) {
    localStorage.setItem("window", JSON.stringify(game.window) );
  }
  if (localStorage.world == null) {
    localStorage.setItem("world", JSON.stringify(game.world) );
  }
}


// pre calculates various things
function preCalc() {
  // calculate from center
  // the character is always in the middle, so we move the background around underneath
  game.center.x = game.canvas.width / 2;
  game.center.y = (game.canvas.height / 8) * 5;
  
  // Draw diggy at
  game.character.drawXAt = game.center.x - (assets["diggy"].w / 2);
  game.character.drawYAt = game.center.y - (assets["diggy"].h / 2);
  
  // calculate where the earth starts and the sky ends
  game.screen.earthBeginsY = game.center.y + (assets["diggy"].h / 2);
  
  // used to calculate props relative to the main character, so that when resizing
  // a window, everything gets reflowed correctly
  game.props.xOffset = (game.canvas.width / 2) - game.assets["diggy"].w / 2;
}


function generateProps() {
  Props.generateLayer1(game);
  Props.generateClouds(game);
}


function calcWorldLayers() {
  // load window layers
  game.window = JSON.parse(localStorage.getItem("window"));
  
  // this calculates the window clipping layers that generate the parallax effect
  for (let i = 0; i <= 9; i++) {
    game.window[i].sx = (game.character.x - (game.center.x * ( i + 1 ) * 3 ) );
    game.window[i].ex = (game.character.x + (game.center.x * ( i + 1 ) * 3 ) );
    game.window[i].sy = (game.character.y - (game.center.y * ( i + 1 ) * 2 ) );
    game.window[i].ey = (game.character.y + (game.center.y * ( i + 1 ) * 2 ) );
  }
  
  // save window layers
  localStorage.setItem("window", JSON.stringify(game.window) );
}


let lastPaintTimeStamp = 0;
let pixelsPerSecondWalking = 240;
let pixelsPerSecondRunning = 600;
let pixelsPerSecondTweaking = 3000;



// use pre-set FPS detector, instead of doing it real time in the paint loop - this will solve the first frame animation starting without actual movement

// fix this shit - sometimes diggy dog isn't landing on the platform randomly, especially on a stairway to heaven

let pixelsPerSecondJumping = 750;





let curWalkTicks = 0;
let frameTick = 0;
let curFrameTick = 0;

function paint() {
  
  animating = true;
  
  let timestamp = performance.now();

  // we'll use a precalculated FPS measurement
  // this is a real time system, but it has a cost...
  // let fps = Math.round( 1000 / (timestamp - lastPaintTimeStamp) );
  
  // when not high, replace any formulas that provide movement via timestamp and lastPaintTimeStamp
  
  let doNextFrame = false;
  
  let dx = game.character.drawXAt;
  let dy = game.character.drawYAt;
  
  
  // ** ASSET SELECTION **
  
  let asset = "";
  
  // handle walking asset selection
  if (walkingRight == true && run == false) asset = walkRightFrames[curRightWalkFrame];
  if (walkingRight == true && run == true) asset = runRightFrames[curRightRunFrame];
  if (walkingLeft == true && run == false) asset = walkLeftFrames[curLeftWalkFrame];
  if (walkingLeft == true && run == true) asset = runLeftFrames[curLeftRunFrame];
  
  // handle jumping asset selection
  if (curJumpHeight > 0) {
    if (walkingRight == true) {
      asset = jumpRight[jumpFrame];
    } else if (walkingLeft == true) {
      asset = jumpLeft[jumpFrame];
    } else {
      if (curDiggyDirection == "right") asset = jumpRight[jumpFrame];
      if (curDiggyDirection == "left") asset = jumpLeft[jumpFrame];
    }
    dx = game.center.x - (assets[asset].w / 2);
    dy = game.center.y - (assets[asset].h / 2);
  }
  
  if (falling == true) {
    if (curDiggyDirection == "right") asset = jumpRight["down"];
    if (curDiggyDirection == "left") asset = jumpLeft["down"];
    dx = game.center.x - (assets[asset].w / 2);
    dy = game.center.y - (assets[asset].h / 2);
  }
  
  if (asset == "") {
    // we reach here if we're standing still
    asset = curDiggyDirection == "right" ? walkRightFrames[0] : walkLeftFrames[0];
  }
  
  if (asset == undefined) {
    // we reach here only if something has gone wrong above
    asset = lastDiggy;
  }
  
  // just in case somehow nothing is selected, we can use this for next time
  lastDiggy = asset;
  
  
  
  
  // ** DRAWING
  
  Sky.draw(game);
  Ground.draw(game);
  Props.drawClouds(game);
  Props.drawTrees(game);
  Props.drawPlatforms(game);
  Props.drawSigns(game);
  Props.drawGems(game);
  
  // draw diggy
  game.ctx.globalAlpha = fadeInFrame < fadeIn.length ? fadeIn[fadeInFrame] : 1;
  // game.ctx.fillRect(dx, dy, game.assets[ asset ].w, game.assets[ asset ].h);
  game.ctx.drawImage(game.assets[ asset ].i, dx, dy, game.assets[ asset ].w, game.assets[ asset ].h);
  game.ctx.globalAlpha = 1;
  if (fadeInFrame < fadeIn.length) {
    game.ctx.font = "28px Open Sans";
    game.ctx.fillStyle = "white";
    game.ctx.fillText("(Ready Fido)", dx - 25, dy - 40);
    
    doNextFrame = true;
    fadeInFrame++;
  } 
  // draw name text above character
  // else {
  //   game.ctx.font = "28px Open Sans";
  //   game.ctx.fillStyle = "#fff4";
  //   game.ctx.fillText("(Ty)", dx + 23, dy - 40);
  // }
  
  Props.drawFlowers(game);
  Props.drawGrass(game);
  // Debug.centerPoint(game);
  
  game.ctx.globalAlpha = 0.5;
  Debug.location(game);
  Debug.version(game);
  // Debug.fps(game, fps);
  game.ctx.globalAlpha = 1;
  
  
  // check for gems
  let {dNextFrame, tScore} = Bounds.gems(game, score);
  score = tScore;
  doNextFrame = dNextFrame == true ? true : doNextFrame;
  if (Bounds.gemsAnimate(game) == true) {
    doNextFrame = true;
  }
  
  
  // ** MOVEMENT **
  
  // this is used so we don't check Bounds.Platforms(game) twice if we don't need to.
  let checkedBoundsPlatforms = false;
  
  // if running, update accordingly
  if (walkingRight == true) {
    // game.character.x += tweak == true ? 100 : 18;
    
    if (run == true && tweak == true) {
      // tweak running
      curFrameTick += frameTick;
      if (curFrameTick >= 1) {
        curRightRunFrame++;
        curFrameTick = 0;
      }
      
      curRightRunFrame = curRightRunFrame >= runRightFrames.length ? 0 : curRightRunFrame;
      game.character.x += lastPaintTimeStamp != 0 ? pixelsPerSecondTweaking * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
    } else if (run == true) {
      // running
      curFrameTick += frameTick;
      if (curFrameTick >= 1) {
        curRightRunFrame++;
        curFrameTick = 0;
      }

      curRightRunFrame = curRightRunFrame >= runRightFrames.length ? 0 : curRightRunFrame;
      game.character.x += lastPaintTimeStamp != 0 ? pixelsPerSecondRunning * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
    } else {
      // walking
      curFrameTick += frameTick;
      if (curFrameTick >= 1) {
        curRightWalkFrame++;
        curFrameTick = 0;
      }
      
      curRightWalkFrame = curRightWalkFrame >= walkRightFrames.length ? 0 : curRightWalkFrame;
      game.character.x += lastPaintTimeStamp != 0 ? pixelsPerSecondWalking * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
    }
    
    // we don't want to be jumping and check this
    // jumping will check this
    // game.character.y < 0 is only temporary, until we start checking for tunnels to fall into
    if (game.character.y < 0 && jumpFrame == "no") {
      // check if we need to fall
      let blockIndex = Bounds.platforms(game, run, falling);
      // console.log("walking right blockIndex:", blockIndex, game.character.y);
      // console.log("walking right game.character.y:", game.character.y);
      
      if (blockIndex == -1) {
        falling = true;
      } else {
        // calculate final offset to drop diggy dog onto block
        game.character.y = game.props.platforms[blockIndex].y - game.assets["diggy1-1"].h;
        // console.log("walking right final offset game.character.y", game.character.y);
        falling = false;
      }
    }
    
    doNextFrame = true;
  }
  
  if (walkingLeft == true) {
    // curLeftRunFrame++;
    // curLeftRunFrame = curLeftRunFrame >= runLeftFrames.length ? 0 : curLeftRunFrame;
    // game.character.x -= tweak == true ? 100 : 18;
    
    if (run == true && tweak == true) {
      curFrameTick += frameTick;
      
      if (curFrameTick >= 1) {
        curLeftRunFrame++;
        curFrameTick = 0;
      }
      curLeftRunFrame = curLeftRunFrame >= runLeftFrames.length ? 0 : curLeftRunFrame;
      game.character.x -= lastPaintTimeStamp != 0 ? pixelsPerSecondTweaking * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
    } else if (run == true) {
      curFrameTick += frameTick;
      
      if (curFrameTick >= 1) {
        curLeftRunFrame++;
        curFrameTick = 0;
      }
      curLeftRunFrame = curLeftRunFrame >= runLeftFrames.length ? 0 : curLeftRunFrame;
      game.character.x -= lastPaintTimeStamp != 0 ? pixelsPerSecondRunning * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
    } else {
      curFrameTick += frameTick;
      
      if (curFrameTick >= 1) {
        curLeftWalkFrame++;
        curFrameTick = 0;
      }
      curLeftWalkFrame = curLeftWalkFrame >= walkLeftFrames.length ? 0 : curLeftWalkFrame;
      game.character.x -= lastPaintTimeStamp != 0 ? pixelsPerSecondWalking * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
    }
    
    // we don't want to be jumping and check this
    // jumping will check this
    // game.character.y < 0 is only temporary, until we start checking for tunnels to fall into
    if (game.character.y < 0 && jumpFrame == "no") {
      // check if we need to fall
      let blockIndex = Bounds.platforms(game, run, falling);
      // console.log("walking left blockIndex:", blockIndex, game.character.y);
      // console.log("walking left game.character.y:", game.character.y);
      if (blockIndex == -1) {
        falling = true;
      } else {
        // calculate final offset to drop diggy dog onto block
        game.character.y = game.props.platforms[blockIndex].y - game.assets["diggy1-1"].h;
        // console.log("walking left final offset game.character.y", game.character.y);
        falling = false;
      }
    }

    doNextFrame = true;
  }
  
  if (falling == true) {
    
    // game.character.y += 20;
    game.character.y += lastPaintTimeStamp != 0 ? pixelsPerSecondJumping * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
    

    let blockIndex = Bounds.platforms(game, run, falling);
    // console.log("falling blockIndex:", blockIndex, game.character.y);
    // console.log("falling game.character.y:", game.character.y);
    if (blockIndex != -1) {
      // calculate final offset to drop diggy dog onto block
      game.character.y = game.props.platforms[blockIndex].y - game.assets["diggy1-1"].h;
      // console.log("falling final offset game.character.y", game.character.y);
      
      falling = false;
    }
    
    if (game.character.y >= 0) {
      game.character.y = 0;
      falling = false;
    }
    doNextFrame = true;
  }
  
  // jumping movement
  if (curJumpHeight > 0) {
    
    // console.log("curJumpHeight:", curJumpHeight);
    // console.log("maxJumpHeight:", maxJumpHeight);
    
    // each jump shows different animation frames
    
    // change jump frame into two frames, as opposed to multiple frames
    curJumpHeight++;
    
    // mjh is a temp variable for maxJumpHeight
    if (curJumpHeight < maxJumpHeight - 1) {
      
      // game.character.y -= 20;
      game.character.y -= lastPaintTimeStamp != 0 ? pixelsPerSecondJumping * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
    
    // } else if (curJumpHeight == mjh) {
    //   jumpFrame = "level";
    } else {
      
      jumpFrame = "down";
      
      let blockIndex = checkedBoundsPlatforms == false ? Bounds.platforms(game, falling) : -1;
      // console.log("curJumpHeight > 0 blockIndex:", blockIndex, game.character.y);
      // console.log("curJumpHeight > 0 game.character.y:", game.character.y);
      
      if (blockIndex == -1) {
        // game.character.y += 20;
        game.character.y += lastPaintTimeStamp != 0 ? pixelsPerSecondJumping * ( (timestamp - lastPaintTimeStamp) / 1000) : 0;
        
      } else {
        // calculate final offset to drop diggy dog onto block
        curJumpHeight = 0;
        game.character.y = game.props.platforms[blockIndex].y - game.assets["diggy1-1"].h;
        // console.log("curJumpHeight > 0 game.character.y", game.character.y);
        jumpFrame = "no";
      }
      
    }
    
    if (game.character.y > 0) {
      game.character.y = 0;
      jumpFrame = "no";
      curJumpHeight = 0;
    }
    doNextFrame = true;
    
  }
  
  if (game.timeOfDay == "night") {
    game.ctx.fillStyle = "#0008";
    game.ctx.fillRect(0, 0, game.canvas.width, game.canvas.height);
  }
  
  // console.log("curJumpHeight", curJumpHeight);
  
  
  if (doNextFrame == true) {
    lastPaintTimeStamp = timestamp;
    calcWorldLayers();
    generateProps();
    window.requestAnimationFrame(paint);
  } else {
    animating = false;
    lastPaintTimeStamp = 0;
  }
  
}


function keyDownHandler(e) {
  
  // console.log(e);
  if (fadeInFrame < fadeIn.length) return;
  
  let doNextFrame = false;
  
  if (e.key == "Shift") {
    
    run = true;
    if (walkingRight == false && walkingLeft == false) doNextFrame = true;
    
  } else if (e.key == "Control" && e.shiftKey == true) {
    
    run = true;
    tweak = true;
    
  } else if (walkingLeft == false && walkingRight == false && e.code == "ArrowRight") {
    
    curFrameTick = 0;
    curDiggyDirection = "right";
    walkingRight = true;
    lastDiggy = "diggy1-1";
    if (e.shiftKey == true) run = true;
    if (curJumpHeight == 0 && falling == false) doNextFrame = true;
    
  } else if (walkingLeft == false && walkingRight == false && e.code == "ArrowLeft") {
    
    curFrameTick = 0;
    curDiggyDirection = "left";
    walkingLeft = true;
    lastDiggy = "diggy2-1";
    if (e.shiftKey == true) run = true;
    if (curJumpHeight == 0 && falling == false) doNextFrame = true;
    
  } else if (e.code == "ArrowDown") {
    
    // if (game.character.y >= 0) {
    //   game.character.y += 10;
    //   doNextFrame = true;
    // }
    
  } else if (e.code == "ArrowUp") {
  } else if (curJumpHeight == 0 && jumpOnceLatch == false && falling == false && e.code == "Space") {
    // console.log("space");
    jumpFrame = "up";
    jumpOnceLatch = true;
    curJumpHeight = 1;
    if (e.shiftKey == true) run = true;
    if (walkingRight == false && walkingLeft == false) doNextFrame = true;
  }
  
  if (doNextFrame == true) {
    calcWorldLayers();
    generateProps();
    if (animating == false) paint();
  }

}


function keyUpHandler(e) {
  
  if (fadeInFrame < fadeIn.length) return;
  
  if (e.key == "Shift") {
    run = false;
  } else if (e.key == "Control" && e.shiftKey == true) {
    tweak = false;
  } else if (e.code == "Space") {
    // console.log("keyup space");
    jumpOnceLatch = false;
    if (falling == false && curJumpHeight > 0) {
      curJumpHeight = maxJumpHeight;
      jumpFrame = "down";
    }
  } else if (e.code == "ArrowLeft") {
    walkingLeft = false;
    curLeftWalkFrame = 0;
    
    if (game.character.y < 0 && jumpFrame == "no") {
      // check if we need to fall
      let blockIndex = Bounds.platforms(game, false, falling);
      // console.log("blockIndex", blockIndex, game.character.y);
      if (blockIndex == -1) {
        falling = true;
      }
    }
    
  } else if (e.code == "ArrowRight") {
    walkingRight = false;
    curRightWalkFrame = 0;
    
    if (game.character.y < 0 && jumpFrame == "no") {
      // check if we need to fall
      let blockIndex = Bounds.platforms(game, false, falling);
      // console.log("blockIndex", blockIndex, game.character.y);
      if (blockIndex == -1) {
        falling = true;
      }
    }
    
  }
  
  if (animating == false) paint();
  
}



</script>