const Types = require("../Types.js");
const Bounds = require("./Bounds.js");

module.exports.generateClouds = function(game) {
  
  let {left, bottom, right, top} = Bounds.checkBounds(game, 9);
  
  if (left == 0 && right == 0 && top == 0 && bottom == 0) return;
  
  // console.log("generating clouds");
  // console.log("left", left);
  // console.log("bottom", bottom);
  // console.log("right", right);
  // console.log("top", top);
  
  // depending on the window size, generate more or less
  // we need this because on smaller screens, things can get too dense
  let amount = Math.round( (right - left) / 4096);
  amount = amount <= 1 ? 2 : amount;
  
  let width = right - left;
  let height = game.window[9].ey - game.window[9].sy;
  let count = Math.round(Math.random() * amount);
  for (let i = 0; i < count; i++) {
    let x = Math.round( Math.random() * width );
    let y = Math.round( Math.random() * (height / 10) );
    let scale = Math.round( (Math.random() * 0.3) * 10) + 7;
    let type = Math.round( Math.random() + 1 );
    game.props.clouds.push({x: left + x, y: y, s: scale, t: type});
  }
  
}


module.exports.drawClouds = function(game) {
  
  let clouds = [];
  // loop through clouds on map
  for (let i in game.props.clouds) {
    // make sure we find something that is within the current view port
    if ( game.props.clouds[i].x >= game.window[9].sx && game.props.clouds[i].x <= game.window[9].ex &&
      game.props.clouds[i].y >= game.window[9].sy && game.props.clouds[i].y <= game.window[9].ey ) {
      
      // draw tree1
      clouds.push(game.props.clouds[i]);
    }
  }
  
  if (game.timeOfDay == "night") 
    game.ctx.globalAlpha = 0.2;
  else
    game.ctx.globalAlpha = 0.7;
    
  // draw clouds
  for (let i in clouds) {
    let scale = clouds[i].s / 10;
    
    let x = ( clouds[i].x - game.character.x ) * 0.1;
    let y = ( clouds[i].y - game.character.y ) * 0.1;
    let w = game.assets[ "cloud" + clouds[i].t ].w * scale;
    let h = game.assets[ "cloud" + clouds[i].t ].h * scale;
    
    game.ctx.drawImage( game.assets[ "cloud" + clouds[i].t ].i, x, y, w, h );
  }
  game.ctx.globalAlpha = 1;
}


module.exports.generateLayer1 = function(game) {
  let {left, bottom, right, top} = Bounds.checkBounds(game, 0);
  
  if (left == 0 && right == 0 && top == 0 && bottom == 0) return;
  
  // console.log("left", left);
  // console.log("bottom", bottom);
  // console.log("right", right);
  // console.log("top", top);
  
  generateTrees(game, left, bottom, right, top);
  generateGrass(game, left, bottom, right, top);
  generateFlowers(game, left, bottom, right, top);
  generatePlatforms(game, left, bottom, right, top);
}


function generateTrees(game, left, bottom, right, top) {
  
  // depending on the window size, generate more or less
  // we need this because on smaller screens, things can get too dense
  let amount = Math.round( (right - left) / 512);
  amount = amount <= 1 ? 2 : amount;
  
  let width = right - left;
  let count = Math.round(Math.random() * amount);
  for (let i = 0; i < count; i++) {
    
    let x = Math.round( Math.random() * width );
    
    let scale = Math.round( (Math.random() * 0.5) * 10) + 7;
    
    game.props.trees.push({x: left + x, s: scale, t: 1});
    
  }
  
}


module.exports.drawTrees = function(game) {
  
  let trees = [];
  
  // loop through trees on map
  for (let i in game.props.trees) {
    
    // make sure we find something that is within the current view port
    if ( game.props.trees[i].x >= game.window[0].sx && game.props.trees[i].x <= game.window[0].ex ) {
      
      // draw tree1
      trees.push(game.props.trees[i]) ;
    }
  }
  
  // sort for view
  trees.sort(function (a,b) {
    return a.s - b.s;
  });
  
  // draw trees
  for (let i in trees) {
    let scale = trees[i].s / 10;
    
    let x = (trees[i].x - game.character.x) + game.props.xOffset;
    
    let plantAt = (game.screen.earthBeginsY - (game.assets[ "tree"+trees[i].t ].h * scale) ) - game.character.y - 20;

    let w = game.assets["tree"+trees[i].t].w * scale;
    let h = game.assets["tree"+trees[i].t].h * scale;
    
    game.ctx.drawImage(game.assets["tree"+trees[i].t].i, x, plantAt, w, h);
  }
  
}


function generateGrass(game, left, bottom, right, top) {
  // depending on the window size, generate more or less
  // we need this because on smaller screens, things can get too dense
  let amount = Math.round( (right - left) / 256);
  amount = amount <= 1 ? 2 : amount;
  
  let width = right - left;
  let count = Math.round(Math.random() * amount);
  for (let i = 0; i < count; i++) {
    let x = Math.round( Math.random() * width );
    game.props.grass.push({x: left + x, t: 1});
  }
}


module.exports.drawGrass = function(game) {
  
  let grass = [];
  
  // loop through trees on map
  for (let i in game.props.grass) {
    
    // make sure we find something that is within the current view port
    if ( game.props.grass[i].x >= game.window[0].sx && game.props.grass[i].x <= game.window[0].ex ) {
      // draw tree1
      grass.push(game.props.grass[i]) ;
    }
  }
  
  // draw grass
  for (let i in grass) {
    let scale = 0.8;
    
    let x = (grass[i].x - game.character.x) + game.props.xOffset;
    
    let plantAt = (game.screen.earthBeginsY - (game.assets[ "grass"+grass[i].t ].h * scale) ) - game.character.y;

    let w = game.assets["grass"+grass[i].t].w * scale;
    let h = game.assets["grass"+grass[i].t].h * scale;
    
    game.ctx.drawImage(game.assets["grass"+grass[i].t].i, x, plantAt + 6, w, h);
  }
  
}


function generateFlowers(game, left, bottom, right, top) {
  // depending on the window size, generate more or less
  // we need this because on smaller screens, things can get too dense
  let amount = Math.round( (right - left) / 256);
  amount = amount <= 1 ? 2 : amount;
  
  let width = right - left;
  let count = Math.round(Math.random() * amount);
  for (let i = 0; i < count; i++) {
    let x = Math.round( Math.random() * width );
    let type = Math.round( Math.random() * 2 ) + 1;
    game.props.flowers.push({x: left + x, t: type});
  }
}


module.exports.drawFlowers = function(game) {
  
  let flowers = [];
  
  // loop through trees on map
  for (let i in game.props.flowers) {
    
    // make sure we find something that is within the current view port
    if ( game.props.flowers[i].x >= game.window[0].sx && game.props.flowers[i].x <= game.window[0].ex ) {
      // draw tree1
      flowers.push(game.props.flowers[i]) ;
    }
  }
  
  // draw flowers
  for (let i in flowers) {
    let scale = 3;
    
    let x = (flowers[i].x - game.character.x) + game.props.xOffset;
    
    let plantAt = (game.screen.earthBeginsY - (game.assets[ "flower"+flowers[i].t ].h * scale) ) - game.character.y;

    let w = game.assets["flower"+flowers[i].t].w * scale;
    let h = game.assets["flower"+flowers[i].t].h * scale;
    
    game.ctx.drawImage(game.assets["flower"+flowers[i].t].i, x, plantAt + 6, w, h);
  }
  
}


// module.exports.generatePlatforms = function(game, left, bottom, right, top) {
//   generatePlatforms(game, left, bottom, right, top);
// }

// quick score LUT
// 0 = nothing, 1 = gold, 2 = rubies, 3 = emeralds, 4 = gold bones, 5 = reg bones
let gemNames = ["never used holder", "gold", "rubies", "emeralds", "goldBones", "bones"];
let gemAssetNames = ["never used holder", "gold1", "ruby1", "emerald1", "bone2", "bone1"];
// points for each gem
let gemPoints = [ 0, 500, 250, 100, 50, 10, ];
// where to position on the platform
// let gemX = [0, 240 / 2, 360 / 2, 480 / 4, 600 / 5, 720 / 6];
// console.log("gemX:", gemX);

function generatePlatforms(game, left, bottom, right, top) {
  
  // console.log("---");
  
  let xFactor = game.assets["diggy1-1"].w;
  let yFactor = game.assets["diggy1-1"].h;
  
  let width = Math.round( (right - left) / xFactor );
  
  let amount = Math.round(Math.random() * (width / 2.5) );
  
  let platforms = [];
  let gems = [];
  for (let i = 0; i < amount; i++) {
    
    let y = Math.round(Math.random() * 8) - 6;
    y = y > 0 ? 0 : y;
    y += 6;
    
    let x = 0, tw = 0, wf = 0;
    if (y != 0) {
      let c = Math.round(Math.random() * 11);
      let c2 = Math.round(Math.random() * 11);
      x = Math.round(Math.random() * width);
      wf = (Math.round(Math.random() * 4) + 2);
      tw = 120 * wf - 120;
      platforms.push({x: left + (x * xFactor), y: 0 - (y * yFactor), w: tw, c: c, c2: c2, t: 0});
    }
    
    // randomly add gems to platforms
    let gemAdded = Math.random(); // 0 or 1
    if (gemAdded >= 0.8 && wf > 0) {
      // put wf into the range of our gem LUT
      wf--;
      // console.log("wf:", wf);
      // calc X's for all gems
      // divide by the width of the platform by the amount of gems to create a unit
      // then position those gems in the center of that unit
      // each unit is 120
      // 120 / 2 = 60
      // graphic width / 2
      // x + ( (platform width unit / 2) - (graphic width / 2) )
      // x + ( 60 - (graphic width / 2) )
      // x + ( 60 - game.assets )
      // graphic width / 2 can be in a LUT
      // x + ( 60 - game.assets[ gemAssetNames[wf] ].c );
      // ( x * mult ) + ( 60 - game.assets[ gemAssetNames[wf] ].c );
      for (let mult = 0; mult < wf; mult++) {
        let gx = left + ( x * xFactor ) + (mult * 120) + ( 60 - game.assets[ gemAssetNames[wf] ].c ) - 10;
        gems.push({t: gemNames[wf], x: gx, y: 0 - ( (y + 1) * yFactor), p: gemPoints[wf], o: 1});
      }
    }
  }
  
  // console.log("gems:", gems);
  
  // should we generate a stairway to heaven?
  if (Math.round(Math.random() * 30) == 30) {
    let x = Math.round(Math.random() * width);
    game.props.signs.push({x: left + (x * xFactor) + 130, t: 0});
    for (let y = 1; y < 201; y+=5) {
      let c = Math.round(Math.random() * 11);
      let c2 = Math.round(Math.random() * 11);
      platforms.push({x: left + (x * xFactor), y: 0 - (y * yFactor), w: 130, c: c, c2: c2, t: 1});
      
      let gx = left + (x * xFactor) + 65 - game.assets[ gemAssetNames[2] ].c - 10;
      gems.push({t: gemNames[2], x: gx, y: 0 - ( (y + 1) * yFactor), p: gemPoints[2], o: 1});
      
    }
    // console.log("generated stairway to heaven");
  }
  
  // should we generate a stairway to heck?
  if (Math.round(Math.random() * 20) == 20) {
    let x = Math.round(Math.random() * width);
    game.props.signs.push({x: left + (x * xFactor) + 130, t: 1});
    console.log("heck at:", left + (x * xFactor) + 130);
    for (let y = 1; y < 201; y+=3) {
      let c = Math.round(Math.random() * 11);
      let c2 = Math.round(Math.random() * 11);
      platforms.push({x: left + (x * xFactor), y: 0 - (y * yFactor), w: 130, c: c, c2: c2, t: 1});
      
      let gx = left + (x * xFactor) + 65 - game.assets[ gemAssetNames[1] ].c - 10;
      gems.push({t: gemNames[1], x: gx, y: 0 - ( (y + 1) * yFactor), p: gemPoints[1], o: 1});
      
      x+=2;
    }
    // console.log("generated stairway to heck");
  }
  
  platforms.sort(function (a,b) {
    return a.x - b.x;
  });
  
  // platforms = [
  //   {x: 0, y: 0, w: 106, c: 0, c2: 0},
  //   {x: 55, y: 0, w: 106, c: 0, c2: 0},
  //   {x: 106, y: 0, w: 106, c: 0, c2: 0},
  //   {x: 211, y: 0, w: 106, c: 0, c2: 0},
  //   {x: 60, y: -256, w: 530, c: 2, c2: 5},
  //   {x: 76, y: -256, w: 318, c: 8, c2: 5},
  // ]
  
  // console.log("platforms", platforms);
  
  // n2 loop that checks whether a startX is within a previous startX to endX
  // if so, extend the endX of the next to the endX of the previous
  // then delete the next
  let i = 0;
  while (i < platforms.length) {
    
    // console.log("i", i, JSON.stringify(platforms[i]));
    
    let j = 0;
    while (j < platforms.length) {
      
      // console.log("j", j, JSON.stringify(platforms[j]));
      
      // we don't want to compare the same object
      if (i == j) {
        j++;
        continue;
      }
      
      // make sure we're on the same Y plain
      if (platforms[i].y == platforms[j].y) {
        
        // console.log("here Y", i, j);
        // check to make see if p[j].x is within p[i].x and (p[i].x + p[i].w)
        if ( platforms[j].x >= platforms[i].x && platforms[j].x <= platforms[i].x + platforms[i].w) {
          // console.log("extend and delete");
          // if (platforms[i].w < 1000) {
            // console.log("length break");
            // platforms[i].w += platforms[j].w;
          // }
          
          let w = (platforms[j].x + platforms[j].w) - (platforms[i].x + platforms[i].w);
          // console.log("w", w);
          // if the width is less than 106, we can assume that the platform is entire 
          // contained within the other platform.  therefore we just delete it
          if (w > 0) {
            platforms[i].w += w;
          }
          
          // console.log("platforms[i].w", platforms[i].w);
          
          platforms.splice(j, 1);
          // console.log("platforms after delete", JSON.stringify(platforms) );
          
        } else {
          j++;
        }
        
      } else {
        j++;
      }
      
    }
    i++;
    
  }
  
  
  game.props.platforms = game.props.platforms.concat(platforms);
  
  
  gems.sort(function (a,b) {
    return a.x - b.x;
  });
  
  // console.log("gems:", gems);
  
  for (let i in gems) {
    game.treasures[ gems[i].t ].push( { x: gems[i].x, y: gems[i].y, p: gems[i].p } );
  }
  
}


let colors = [
  {r: 255, g: 0, b: 0},
  {r: 0, g: 255, b: 0},
  {r: 0, g: 0, b: 255},
  {r: 255, g: 255, b: 0},
  {r: 255, g: 0, b: 255},
  {r: 0, g: 255, b: 255},
  
  {r: 128, g: 0, b: 0},
  {r: 0, g: 128, b: 0},
  {r: 0, g: 0, b: 128},
  {r: 128, g: 128, b: 0},
  {r: 128, g: 0, b: 128},
  {r: 0, g: 128, b: 128},
]

module.exports.drawPlatforms = function(game) {
  let platforms = [];
  
  // loop through trees on map
  for (let i in game.props.platforms) {
    
    // make sure we find something that is within the current view port
    if ( game.props.platforms[i].x >= game.window[0].sx && game.props.platforms[i].x <= game.window[0].ex ) {
      platforms.push(game.props.platforms[i]);
    }
  }
  
  // console.log("platforms");
  // console.log(platforms);
  
  let height = (game.assets["diggy1-1"].h / 3) * 2 - 10;
  // game.ctx.fillStyle = "red";
  
  // draw platforms
  for (let i in platforms) {
    let x = (platforms[i].x - game.character.x) + game.props.xOffset;
    let y = (game.screen.earthBeginsY - game.assets["diggy1-1"].h + platforms[i].y) - game.character.y - 11;
    
    let width = platforms[i].w;
    
    // console.log("c", c);
    let color = "rgb(" + colors[platforms[i].c].r + "," + colors[platforms[i].c].g + "," + colors[platforms[i].c].b + ")";
    let color2 = "rgb(" + colors[platforms[i].c2].r + "," + colors[platforms[i].c2].g + "," + colors[platforms[i].c2].b + ")";
    
    if (platforms[i].t == 0) {
      y -= 7;
      let platformTexture = game.ctx.createLinearGradient(0, y, 0, y + height);
      platformTexture.addColorStop(0, color);
      platformTexture.addColorStop(0.5, color2);
      platformTexture.addColorStop(1, "white");
      game.ctx.fillStyle = platformTexture;
  
      game.ctx.beginPath();
      game.ctx.strokeStyle = "#0005";
      game.ctx.lineWidth = 3;
      
      game.ctx.moveTo(x + 1, y + height + 2);
      game.ctx.lineTo(x + width - 1, y + height + 2);
      game.ctx.stroke();
  
      game.ctx.lineWidth = 1;
      
      game.ctx.beginPath();
      game.ctx.moveTo(x, y + height);
      game.ctx.lineTo(x + 35, y);
      game.ctx.lineTo(x + width - 35, y);
      game.ctx.lineTo(x + width, y + height);
      game.ctx.fill();
      
      game.ctx.strokeStyle = "#fffa";
      game.ctx.beginPath();
      game.ctx.moveTo(x, y + height);
      game.ctx.lineTo(x + 35, y);
      game.ctx.lineTo(x + width - 35, y);
      game.ctx.lineTo(x + width, y + height);
      game.ctx.lineTo(x, y + height);
      game.ctx.stroke();
    }
    
    if (platforms[i].t == 1) {

      let platformTexture = game.ctx.createLinearGradient(0, y, 0, y + height - 10);
      platformTexture.addColorStop(0, color);
      platformTexture.addColorStop(0.5, color2);
      platformTexture.addColorStop(1, "white");
      game.ctx.fillStyle = platformTexture;
  
      game.ctx.beginPath();
      game.ctx.strokeStyle = "#0005";
      game.ctx.lineWidth = 3;
      
      game.ctx.moveTo(x + 1, y + 16);
      game.ctx.lineTo(x + (width / 2), y + height + 1 );
      game.ctx.lineTo(x + width - 1, y + 16);
      
      game.ctx.stroke();
  
      game.ctx.lineWidth = 1;
      
      game.ctx.beginPath();
      game.ctx.moveTo(x, y + 15);
      game.ctx.lineTo(x + 15, y);
      game.ctx.lineTo(x + width - 15, y);
      game.ctx.lineTo(x + width, y + 15);
      game.ctx.lineTo(x + (width / 2), y + height );
      game.ctx.fill();
      
      game.ctx.strokeStyle = "#fffa";
      game.ctx.beginPath();
      game.ctx.moveTo(x, y + 15);
      game.ctx.lineTo(x + 15, y);
      game.ctx.lineTo(x + width - 15, y);
      game.ctx.lineTo(x + width, y + 15);
      game.ctx.lineTo(x + (width / 2), y + height );
      game.ctx.lineTo(x, y + 15);
      
      game.ctx.stroke();
        
    }
    
  }
  
}


module.exports.drawSigns = function(game) {
  let signs = [];
  
  // loop through trees on map
  for (let i in game.props.signs) {
    
    // make sure we find something that is within the current view port
    if ( game.props.signs[i].x >= game.window[0].sx && game.props.signs[i].x <= game.window[0].ex ) {
      signs.push(game.props.signs[i]);
    }
  }
  
  for (let i in signs) {
    let x = (signs[i].x - game.character.x) + game.props.xOffset;
    let y = (game.screen.earthBeginsY - game.assets[ "sign" + signs[i].t ].h ) - game.character.y - 11;
    
    game.ctx.drawImage(game.assets[ "sign" + signs[i].t ].i, x, y, game.assets[ "sign" + signs[i].t ].w, game.assets[ "sign" + signs[i].t ].h);
  }
  
}


module.exports.generateGems = function() {
  
}


module.exports.drawGems = function(game) {
  let bones = [];
  let goldBones = [];
  let emeralds = [];
  let rubies = [];
  let gold = [];
  
  // loop through bones on map
  for (let i in game.treasures.bones) {
    // make sure we find something that is within the current view port
    if ( game.treasures.bones[i].x >= game.window[0].sx && game.treasures.bones[i].x <= game.window[0].ex ) {
      bones.push(game.treasures.bones[i]);
    }
  }
  
  // loop through goldBones on map
  for (let i in game.treasures.goldBones) {
    // make sure we find something that is within the current view port
    if ( game.treasures.goldBones[i].x >= game.window[0].sx && game.treasures.goldBones[i].x <= game.window[0].ex ) {
      goldBones.push(game.treasures.goldBones[i]);
    }
  }
  
  // loop through emeralds on map
  for (let i in game.treasures.emeralds) {
    // make sure we find something that is within the current view port
    if ( game.treasures.emeralds[i].x >= game.window[0].sx && game.treasures.emeralds[i].x <= game.window[0].ex ) {
      emeralds.push(game.treasures.emeralds[i]);
    }
  }
  
  // loop through rubies on map
  for (let i in game.treasures.rubies) {
    // make sure we find something that is within the current view port
    if ( game.treasures.rubies[i].x >= game.window[0].sx && game.treasures.rubies[i].x <= game.window[0].ex ) {
      rubies.push(game.treasures.rubies[i]);
    }
  }
  
  
  // loop through gold on map
  for (let i in game.treasures.gold) {
    // make sure we find something that is within the current view port
    if ( game.treasures.gold[i].x >= game.window[0].sx && game.treasures.gold[i].x <= game.window[0].ex ) {
      gold.push(game.treasures.gold[i]);
    }
  }
  
  
  for (let i in bones) {
    let x = (bones[i].x - game.character.x) + game.props.xOffset;
    let y = (game.screen.earthBeginsY - game.assets["bone1"].h + bones[i].y) - game.character.y - 22;
    // game.ctx.fillStyle = "red";
    // game.ctx.fillRect(x, y, 40, 40);
    game.ctx.globalAlpha = bones[i].o;
    game.ctx.drawImage(game.assets["bone1"].i, x, y, 40, 40);
    game.ctx.globalAlpha = 1;
  }
  
  for (let i in goldBones) {
    let x = (goldBones[i].x - game.character.x) + game.props.xOffset;
    let y = (game.screen.earthBeginsY - game.assets["bone2"].h + goldBones[i].y) - game.character.y - 22;
    game.ctx.globalAlpha = goldBones[i].o;
    game.ctx.drawImage(game.assets["bone2"].i, x, y, 40, 40);
    game.ctx.globalAlpha = 1;
  }
  
  for (let i in emeralds) {
    let x = (emeralds[i].x - game.character.x) + game.props.xOffset;
    let y = (game.screen.earthBeginsY - game.assets["emerald1"].h + emeralds[i].y) - game.character.y - 22;
    game.ctx.globalAlpha = emeralds[i].o;
    game.ctx.drawImage(game.assets["emerald1"].i, x, y, 40, 40);
    game.ctx.globalAlpha = 1;
  }
  
  for (let i in rubies) {
    let x = (rubies[i].x - game.character.x) + game.props.xOffset;
    let y = (game.screen.earthBeginsY - game.assets["ruby1"].h + rubies[i].y) - game.character.y - 22;
    game.ctx.globalAlpha = rubies[i].o;
    game.ctx.drawImage(game.assets["ruby1"].i, x, y, 40, 35);
    game.ctx.globalAlpha = 1;
  }
  
  for (let i in gold) {
    let x = (gold[i].x - game.character.x) + game.props.xOffset;
    let y = (game.screen.earthBeginsY - game.assets["gold1"].h + gold[i].y) - game.character.y - 22;
    game.ctx.globalAlpha = gold[i].o;
    game.ctx.drawImage(game.assets["gold1"].i, x, y, 40, 40);
    game.ctx.globalAlpha = 1;
  }
  
}

