<style>

.background{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(white 15%, lightblue 30%, #a05a2c 80%);
}

.power-up{
  margin: auto;
  width: 300px;
  border: 3px #000 solid;
  height: 30px;
  padding: 0;
}
.power-meter{
  margin: 0;
  height: 30px;
  background-color: #000;
}

.logo{
  width: 548px;
  margin: auto;
  padding: 100px 0;
}

.loading{
  width: 548px;
  margin: auto;
  text-align: center;
}

.diggy{
  width: 241px;
  margin: auto;
  padding: 100px 0 0 0;
  text-align: center;
}

.start{
  margin: auto;
  width: 200px;
}
button{
  border: 3px rgba(255,0,0,0.7) solid;
  background-color: rgba(255,0,0,0.5);
  font-size: 30px;
  color: white;
  cursor: pointer;
  width: 200px;
}
button:hover{
  border: 3px rgba(255,0,0,0.9) solid;
  background-color: rgba(255,0,0,0.7);
}

@media screen and (max-width: 768px) {
  .logo{
    width: 245px;
  }
  .loading{
    width: 245px;
  }
}

</style>


<div class="background">
  <div class="desktop logo">
    <img src="/i/logo-desktop.svg" alt="Super Jumpy Dog">
  </div>
  
  <div class="mobile logo">
    <img src="/i/logo-mobile.svg" alt="Super Jumpy Dog">
  </div>
  
  
  {#if showOkay == true}
    <div class="start">
      <button on:click="{ () => {dispatch('loaded')} }">Start</button>
    </div>
  {:else}
    <div class="power-up">
      <div class="power-meter" style="width: {progress}px;"></div>
    </div>
    <div class="loading">
      Loading...
    </div>
  {/if}
  
  <div class="desktop diggy">
    <img src="/i/diggy-splash.svg" alt="Super Jumpy Dog">
  </div>
  
  <div class="mobile diggy">
    <img src="/i/diggy-splash.svg" alt="Super Jumpy Dog">
  </div>  
</div>

<script>

export let assets = [];
export let soundAssets = [];

let progress = 0;
let imagesToLoad = [
  "diggy",
  
  "diggy1-1", "diggy1-2", "diggy1-3", "diggy1-4", "diggy1-5",
  
  "diggy2-1", "diggy2-2", "diggy2-3", "diggy2-4", "diggy2-5",

  "diggy3-1", "diggy3-2", "diggy3-3", "diggy3-4",

  "diggy4-1", "diggy4-2", "diggy4-3","diggy4-4",

  "diggy10-1", "diggy10-2", "diggy10-3",
  
  "diggy11-1", "diggy11-2", "diggy11-3",
  
  "squirrel",
  
  "cloud1", "cloud2",
  
  "tree1", "grass1",
  
  "flower1", "flower2", "flower3",
  
  "sign0", "sign1",
  
  "bone1", "bone2", "emerald1", "ruby1", "gold1",
];

let audioToLoad = [
  "gem01", "gem02", 
];

let incrementBy = 0;
let showOkay = false;

import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

import { onMount } from "svelte";
onMount(async () => {
  incrementBy = 300 / imagesToLoad.length;
  loadImages();
  loadAudio();
});


function loadImages() {
  for (let i in imagesToLoad) {
    var img = new Image();
    img.onload = function() {
      progress += incrementBy;

      if (Math.round(progress) == 300) {
        dispatch('loaded');
        // showOkay = true;
      }
    }
    img.src = "/i/" + imagesToLoad[i] + ".svg";
    
    // assets.push({f: imagesToLoad[i], i: img});
    assets[ imagesToLoad[i] ] = {i: img, w: 0, h: 0, t: 0};
  }
}


function loadAudio() {
  for (let i in audioToLoad) {
    soundAssets[ audioToLoad[i] ] = new Audio(`/s/${ audioToLoad[i] }.mp3`);
  }
}

</script>