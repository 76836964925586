// Tile types
let types = [];

// surfaces
types["water"] = 0;
types["void"] = 1; // when digdog tunnels underground

// baddies
types["squirrel"] = 10;
types["kitty"] = 11;
types["badger"] = 12;
types["bear"] = 13;
types["shark"] = 14;

// allies
types["eagle"] = 20;
types["human"] = 21;
types["magicFrog"] = 22;

// props
types["cloud1"] = 100;
types["cloud2"] = 101;
types["tree1"] = 200;
types["fireHydrant"] = 300;
types["house"] = 400;
types["bone"] = 500;
types["fish"] = 600;
types["flower1"] = 700;
types["flower2"] = 701;
types["flower3"] = 702;
types["grass1"] = 800;
types["sign0"] = 900;
types["sign1"] = 1000;

// treasure
types["bone1"] = 100000;
types["bone2"] = 100001;
types["emerald1"] = 100010;
types["ruby1"] = 100020;
types["gold1"] = 100030;

module.exports = types;