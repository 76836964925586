module.exports.draw = function(game) {
  
  game.ctx.lineWidth = 0;
  
  let sy = game.screen.earthBeginsY - game.character.y - 25;
  
  let ground = game.ctx.createLinearGradient(0, sy, 0, sy + game.screen.tileSizeHeight * 11);
  ground.addColorStop(0, "#55d400");
  ground.addColorStop(0.05, "#8ef044");
  ground.addColorStop(0.06, "#ccf0b0");
  ground.addColorStop(0.065, "#a05a2c");
  ground.addColorStop(0.08, "#ce7238");
  ground.addColorStop(0.10, "#ce7238");
  ground.addColorStop(0.2, "#a05a2c");
  ground.addColorStop(0.30, "#a05a2c");
  ground.addColorStop(0.4, "#7e4522");
  ground.addColorStop(0.50, "#7e4522");
  ground.addColorStop(0.6, "#582f17");
  ground.addColorStop(0.70, "#582f17");
  ground.addColorStop(0.8, "#422311");
  ground.addColorStop(0.90, "#422311");
  ground.addColorStop(1, "#28150a");
  
  game.ctx.fillStyle = ground;
  game.ctx.fillRect(0, sy, game.canvas.width, game.screen.tileSizeHeight * 11);
  
  // make it easy for canvas
  let th = sy + game.screen.tileSizeHeight * 11;
  let sh = th > 0 ? th : 0;
  
  // draw infinite underground
  game.ctx.fillStyle = "#28150a";
  game.ctx.fillRect(0, sh, game.canvas.width, game.canvas.height);
  
}