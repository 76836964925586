module.exports.draw = function(game) {
  let sh = game.screen.earthBeginsY - game.character.y;
  if (sh <= 0) return;
  
  let sky = null;
  if (game.timeOfDay == "day") {
    sky = game.ctx.createLinearGradient(0, 0, 200, sh);
    sky.addColorStop(0, "white");
    sky.addColorStop(0.45, "#add8e6");
  }
  if (game.timeOfDay == "night") {
    sky = game.ctx.createLinearGradient(0, 0, 0, sh);
    sky.addColorStop(0, "black");
    sky.addColorStop(0.75, "black");
    sky.addColorStop(0.9, "#333");
  }
  
  game.ctx.fillStyle = sky;
  game.ctx.fillRect(0, 0, game.canvas.width, sh);

}