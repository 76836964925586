// checks the window to see if anything to the left, left bottom, bottom, right bottom, or the right
// needs to be generated based on a layer.  returns a JSON object thusly:
/* 
{
  left: 0, 
  bottom: 0, 
  right: 0, 
  top: 0,
}
*/

module.exports.checkBounds = function(game, layer) {
  
  game.world = JSON.parse( localStorage.getItem("world") );
  
  let out = {
    left: 0, 
    bottom: 0, 
    right: 0, 
    top: 0,
  }
  
  // get half of window so that we can add that to the width that needs to be checked against
  let windowWidth = (game.window[layer].ex - game.window[layer].sx);
  let windowHeight = (game.window[layer].ey - game.window[layer].sy);
  
  // calculate the main character into this layers' space
  let gcx = game.character.x;
  let gcy = game.character.y;
  
  if (game.world[layer].ex - gcx < windowWidth) {
    out.right = gcx + (windowWidth * 2);
    game.world[layer].ex = out.right;
    // console.log("--generate right of screen, layer " + layer);
  }
  
  if (gcx - game.world[layer].sx < windowWidth) {
    out.left = gcx - (windowWidth * 2);
    game.world[layer].sx = out.left;
    // console.log("--generate left of screen, layer " + layer);
  }
  
  if (game.world[layer].ey - gcy < windowHeight) {
    out.bottom = gcy + (windowHeight * 2);
    game.world[layer].ey = out.bottom;
    // console.log("--generate bottom of screen, layer " + layer);
  }
  
  if (gcy - game.world[layer].sy < windowHeight) {
    out.top = gcy - (windowHeight * 2);
    game.world[layer].sy = out.top;
    // console.log("--generate top of screen, layer " + layer);
  }
  
  if (out.left == 0 && out.right != 0) {
    out.left = out.right - windowWidth;
  }
  if (out.left != 0 && out.right == 0) {
    out.right = out.left + windowWidth;
  }
  
  localStorage.setItem("world", JSON.stringify(game.world) );
  
  return out;
}



module.exports.platforms = function(game, run, fallingInProgress) {
  
  // add and subtract 30 here to allow some wiggle room in what gets detected.
  // this basically makes diggy a bit smaller.
  
  let tx = 30;
  
  // give a little extra runway to jump when running
  // this improves player ergonomics considerably
  if (run == true) {
    tx = 85;
  }
  
  if (fallingInProgress == true) {
    // console.log("fallingInProgress");
    tx = 65;
  }
  
  // console.log("tx", tx);
  
  let gcx = game.character.x + tx;
  let ex = game.character.x + game.assets["diggy1-1"].w - tx;
  // let sy = Math.round(game.character.y);
  let ey = Math.round(game.character.y + game.assets["diggy1-1"].h);
  
  // console.log("gcx and ex", gcx, ex);
  
  for (let i in game.props.platforms) {

    // within x boundaries?
    // if (gcx >= game.props.platforms[i].x && ex <= game.props.platforms[i].x + game.assets["diggy1-1"].w) {
    if (gcx >= game.props.platforms[i].x && ex <= game.props.platforms[i].x + game.props.platforms[i].w) {
      // console.log("within x boundary");
      
      // are we above the block?
      // console.log("ey ("+ey+") - game.props.platforms[i].y ("+game.props.platforms[i].y+")", game.props.platforms[i].y + ey);
      if (ey <= game.props.platforms[i].y && (game.props.platforms[i].y - ey) <= 25) {
        // console.log("within y boundary, index: ", i);
        return i;
      }
    }
  }
  
  return -1;
}


/* 
if d.x >= ga.x && d.x <= ga.x + ga.w 
if d.x + d.w >= ga.x && d.x + d.w <= ga.x + ga.w 

if d.y >= ga.y && d.y <= ga.y + ga.h 
if d.y + d.h >= ga.y && d.y + d.h <= ga.y + ga.h 
*/

// console.log("game.character.x:", game.character.x);
// console.log("game.treasures.bones[i].x:", game.treasures.bones[i].x);
// console.log("game.character.x + game.assets[diggy1-1].w:", game.character.x + game.assets["diggy1-1"].w);
// console.log("game.treasures.bones[i].x + game.assets[bone1].w:", game.treasures.bones[i].x + 40);

// game.character.x + game.assets["diggy1-1"].w <= game.treasures.bones[i].x + 40

/* 
we need to check two different points on the x axis.  left and right of the main character.  one of these two points needs to be within the range of the treasure.

an issue is that the gem is smaller than diggy.  that means that if diggy falls on to a gem, the gem might not be detected because there are no points inside of the gem.

there needs to be multiple points around diggy calculated to check against the gem.

*/
module.exports.gems = function(game, tScore) {
  
  // calculate check points
  // console.log("game.assets[diggy1-1].w:", game.assets["diggy1-1"].w);
  let gcx1 = game.character.x;
  let gcx2 = game.character.x + game.character.point2;
  let gcx3 = game.character.x + game.character.point3;
  let gcx4 = game.character.x + game.character.point4;
  
  let gcy1 = game.character.y;
  let gcy2 = game.character.y + 40;
  
  let dNextFrame = false;
  
  for (let i in game.treasures.bones) {
    
    // game.character.x + game.assets["diggy1-1"].w
    if (game.treasures.bones[i].o < 1) continue;
    
    let gtx1 = game.treasures.bones[i].x;
    let gtx2 = game.treasures.bones[i].x + 40;
    
    if (
      // this ifs multiple points against a gem, depending on where diggy is located 
      (gcx1 >= gtx1 && gcx1 <= gtx2) ||
      (gcx2 >= gtx1 && gcx2 <= gtx2) ||
      (gcx3 >= gtx1 && gcx3 <= gtx2) ||
      (gcx4 >= gtx1 && gcx4 <= gtx2)
    ) {
      
      let gty1 = game.treasures.bones[i].y;
      let gty2 = game.treasures.bones[i].y + 40;
      
      if ( 
        (gcy1 >= gty1 && gcy1 <= gty2) ||
        (gcy2 >= gty1 && gcy2 <= gty2) 
      ) {
        // console.log("here");
        game.sounds["gem01"].cloneNode(false).play();
        game.treasures.bones[i].o = 0.95;
        game.treasures.bones[i].y -= 10;
        tScore += game.treasures.bones[i].p;
        dNextFrame = true;
      }
      
    }
    
  }
  
  for (let i in game.treasures.goldBones) {
    
    // game.character.x + game.assets["diggy1-1"].w
    if (game.treasures.goldBones[i].o < 1) continue;
    
    let gtx1 = game.treasures.goldBones[i].x;
    let gtx2 = game.treasures.goldBones[i].x + 40;
    
    if (
      // this ifs multiple points against a gem, depending on where diggy is located 
      (gcx1 >= gtx1 && gcx1 <= gtx2) ||
      (gcx2 >= gtx1 && gcx2 <= gtx2) ||
      (gcx3 >= gtx1 && gcx3 <= gtx2) ||
      (gcx4 >= gtx1 && gcx4 <= gtx2)
    ) {
      
      let gty1 = game.treasures.goldBones[i].y;
      let gty2 = game.treasures.goldBones[i].y + 40;
      
      if ( 
        (gcy1 >= gty1 && gcy1 <= gty2) ||
        (gcy2 >= gty1 && gcy2 <= gty2) 
      ) {
        // console.log("here");
        game.sounds["gem01"].cloneNode(false).play();
        game.treasures.goldBones[i].o = 0.95;
        game.treasures.goldBones[i].y -= 10;
        tScore += game.treasures.goldBones[i].p;
        dNextFrame = true;
      }
      
    }
    
  }
  
  for (let i in game.treasures.emeralds) {
    
    // game.character.x + game.assets["diggy1-1"].w
    if (game.treasures.emeralds[i].o < 1) continue;
    
    let gtx1 = game.treasures.emeralds[i].x;
    let gtx2 = game.treasures.emeralds[i].x + 40;
    
    if (
      // this ifs multiple points against a gem, depending on where diggy is located 
      (gcx1 >= gtx1 && gcx1 <= gtx2) ||
      (gcx2 >= gtx1 && gcx2 <= gtx2) ||
      (gcx3 >= gtx1 && gcx3 <= gtx2) ||
      (gcx4 >= gtx1 && gcx4 <= gtx2)
    ) {
      
      let gty1 = game.treasures.emeralds[i].y;
      let gty2 = game.treasures.emeralds[i].y + 40;
      
      if ( 
        (gcy1 >= gty1 && gcy1 <= gty2) ||
        (gcy2 >= gty1 && gcy2 <= gty2) 
      ) {
        // console.log("here");
        game.sounds["gem01"].cloneNode(false).play();
        game.treasures.emeralds[i].o = 0.95;
        game.treasures.emeralds[i].y -= 10;
        tScore += game.treasures.emeralds[i].p;
        dNextFrame = true;
      }
      
    }
    
  }
  
  for (let i in game.treasures.rubies) {
    
    // game.character.x + game.assets["diggy1-1"].w
    if (game.treasures.rubies[i].o < 1) continue;
    
    let gtx1 = game.treasures.rubies[i].x;
    let gtx2 = game.treasures.rubies[i].x + 40;
    
    if (
      // this ifs multiple points against a gem, depending on where diggy is located 
      (gcx1 >= gtx1 && gcx1 <= gtx2) ||
      (gcx2 >= gtx1 && gcx2 <= gtx2) ||
      (gcx3 >= gtx1 && gcx3 <= gtx2) ||
      (gcx4 >= gtx1 && gcx4 <= gtx2)
    ) {
      
      let gty1 = game.treasures.rubies[i].y;
      let gty2 = game.treasures.rubies[i].y + 40;
      
      if ( 
        (gcy1 >= gty1 && gcy1 <= gty2) ||
        (gcy2 >= gty1 && gcy2 <= gty2) 
      ) {
        // console.log("here");
        game.sounds["gem01"].cloneNode(false).play();
        game.treasures.rubies[i].o = 0.95;
        game.treasures.rubies[i].y -= 10;
        tScore += game.treasures.rubies[i].p;
        dNextFrame = true;
      }
      
    }
    
  }
  
  for (let i in game.treasures.gold) {
    
    // game.character.x + game.assets["diggy1-1"].w
    if (game.treasures.gold[i].o < 1) continue;
    
    let gtx1 = game.treasures.gold[i].x;
    let gtx2 = game.treasures.gold[i].x + 40;
    
    if (
      // this ifs multiple points against a gem, depending on where diggy is located 
      (gcx1 >= gtx1 && gcx1 <= gtx2) ||
      (gcx2 >= gtx1 && gcx2 <= gtx2) ||
      (gcx3 >= gtx1 && gcx3 <= gtx2) ||
      (gcx4 >= gtx1 && gcx4 <= gtx2)
    ) {
      
      let gty1 = game.treasures.gold[i].y;
      let gty2 = game.treasures.gold[i].y + 40;
      
      if ( 
        (gcy1 >= gty1 && gcy1 <= gty2) ||
        (gcy2 >= gty1 && gcy2 <= gty2) 
      ) {
        // console.log("here");
        game.sounds["gem02"].cloneNode(false).play();
        game.treasures.gold[i].o = 0.95;
        game.treasures.gold[i].y -= 10;
        tScore += game.treasures.gold[i].p;
        dNextFrame = true;
      }
      
    }
    
  }
  
  return {dNextFrame, tScore};
  
}


module.exports.gemsAnimate = function(game) {
  let dNextFrame = false;
  for (let i in game.treasures.bones) {
    if (game.treasures.bones[i].o < 1) {
      game.treasures.bones[i].o -= 0.05;
      game.treasures.bones[i].y -= 15;
      
      if (game.treasures.bones[i].o <= 0) {
        delete game.treasures.bones[i];
      }
      dNextFrame = true;
    }
  }
  
  for (let i in game.treasures.goldBones) {
    if (game.treasures.goldBones[i].o < 1) {
      game.treasures.goldBones[i].o -= 0.05;
      game.treasures.goldBones[i].y -= 15;
      
      if (game.treasures.goldBones[i].o <= 0) {
        delete game.treasures.goldBones[i];
      }
      dNextFrame = true;
    }
  }
  
  for (let i in game.treasures.emeralds) {
    if (game.treasures.emeralds[i].o < 1) {
      game.treasures.emeralds[i].o -= 0.05;
      game.treasures.emeralds[i].y -= 15;
      
      if (game.treasures.emeralds[i].o <= 0) {
        delete game.treasures.emeralds[i];
      }
      dNextFrame = true;
    }
  }
  
  for (let i in game.treasures.rubies) {
    if (game.treasures.rubies[i].o < 1) {
      game.treasures.rubies[i].o -= 0.05;
      game.treasures.rubies[i].y -= 15;
      
      if (game.treasures.rubies[i].o <= 0) {
        delete game.treasures.rubies[i];
      }
      dNextFrame = true;
    }
  }
  
  for (let i in game.treasures.gold) {
    if (game.treasures.gold[i].o < 1) {
      game.treasures.gold[i].o -= 0.05;
      game.treasures.gold[i].y -= 15;
      
      if (game.treasures.gold[i].o <= 0) {
        delete game.treasures.gold[i];
      }
      dNextFrame = true;
    }
  }
  
  return dNextFrame;
}