<style>

.dialog{
	z-index: 100;
	position: fixed;
	top: 5%;
	/* bottom: 10%; */
	left: 50%;
	width: 500px;
	margin-left: -270px;
	border: 2px lightgray solid;
  background-color: rgba(255,255,255,0.9);
	padding: 20px;
	box-shadow: 0 10px 10px rgba(0,0,0,0.3);
}
@media screen and (max-width: 768px) {
  .dialog{
    z-index: 100;
    position: fixed;
    width: auto;
    margin-left: auto;
    top: 5%;
    left: 8px;
    right: 8px;
    border: 2px lightgray solid;
  	background-color: rgba(255,255,255,0.9);
    padding: 10px;
    box-shadow: 0 10px 10px rgba(0,0,0,0.3);
  }
}

.header {
	display: grid;
	grid-template-columns: 80% auto;
}
.title{
	grid-column: 1;
}
.close{
	grid-column: 2;
	text-align: right;
	cursor: pointer;
	font-size: 25px;
}

.buttons{
	display: grid;
	margin-top: 20px;
}
.cancel{
	grid-column: 1;
}
.accept{
	grid-column: 2;
	text-align: right;
}

</style>

{#if show == true}

<div class="overlay" on:click="{ () => { dispatch('close') } }"></div>

<div class="dialog">
	
	<div class="header">
		<div class="title">
			<h3>{title}</h3>
		</div>
		{#if close == true}
			<div class="close" on:click="{ () => { dispatch('close') } }">
				<i class="icon-cancel-circle2"></i>
			</div>
		{/if}
	</div>
	
	<slot></slot>
	
	<div class="buttons">
		<div class="cancel">
			{#if cancel == true}
			<button on:click="{ () => { dispatch('cancel'); } }">Cancel</button>
			{/if}
		</div>
		<div class="accept">
			{#each buttons as item}
			<span><button on:click="{ () => {dispatch(item.callback)} }" disabled="{item.disabled}">{item.text}</button></span>
			{/each}
		</div>
	</div>
	
</div>

{/if}


<script>

import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

export let show = true;
export let title = "Dialog";
export let close = true;
export let cancel = true;
export let buttons = [ {text: "send", callback: "send", disabled: false, }, {text: "send2", callback: "send2", disabled: false, }, ];
// export let buttons = [ {type:"cancel", }, ];


function buttonHelper(b) {
	for (let i in buttons) {
		if (buttons[i].type == b) {
			return true;
		}
	}
	
	return false;
}

</script>