<style>

header{
  color: white;
  background-color: black;
  height: 32px;
  margin: 0;
  display: grid;
  /* 134 = 32 for laser eyes icon + 102 for meter */
  grid-template-columns: auto 134px 102px;
}
.about{
  grid-column: 1;
}
.laser-power-up{
  grid-column: 2;
  padding: 4px 0 0 0;
}
.score{
  grid-column: 3;
  text-align: right;
  font-size: 25px;
  margin: 0 5px;
}
button{
  height: 32px;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  color: #777;
  background-color: #111;
}
button:hover{
  background-color: #444;
  color: white;
}

.about-button{
  background-color: #f4f4f4;
  border: 1px #cccccc solid;
  padding: 5px 10px;
}
</style>

<header>
  <div class="about"><button on:click="{aboutHelper}">About</button></div>
  <div class="laser-power-up"><LaserPowerUp laserPowerUp="{laserPowerUp}"></LaserPowerUp></div>
  <div class="score">{score}</div>
</header>

<Dialog show="{showAbout}" cancel="{false}" title="About Super Jumpy Dog!" buttons="{aboutButtons}" on:close="{closeHelper}">
  
  <h4>Mobile</h4>
  <p>Sorry, mobile isn't supported yet.</p>
  
  <h4>ALPHA Version</h4>
  <p>Definitely not feature complete.  I'm trying to do a release every two weeks.</p>
  
  <h4>About</h4>
  <p>Super Jumpy Dog is a procedurally generated game with infinite game play.  Everytime you start a new game, everything changes.  Eventually you'll be able to save games and come back to them.</p>
  
  <h4>Instructions</h4>
  <div class="desktop">
    <p><span class="about-button">Left</span> and <span class="about-button">Right</span> Arrow keys to move DigDog</p>
    <p>Press <span class="about-button">Shift</span> while walking to run fast</p>
    <p>Press <span class="about-button">Space</span> to Jump</p>
    <!-- <p>Press <span class="about-button">D</span> to growl</p>
    <p>Press <span class="about-button">F</span> to fire laser eyes</p> -->
  </div>
  <div class="mobile">
    <p>Swipe up / down / left / right to move DigDog</p>
    <!-- <p>Press <span class="about-button">S</span> to bark</p>
    <p>Press <span class="about-button">D</span> to growl</p>
    <p>Press <span class="about-button">F</span> to fire laser eyes</p> -->
  </div>
  <!-- <h4>Objective</h4>
  <p>Kill as many baddies you can and get as far out into the map as possible!</p>
  <p>Games are saved in realtime, so you can quickly close your browser tab and come back to exactly where you left off by reopening a new tab.</p> -->

  <h4>Author</h4>
  <p>Written by <a href="https://jeffclemmer.net" target="_blank">Jeff Clemmer</a></p>
  
  <!-- <p><a href="" class="about-button">Donate to me</a></p>
  <p><a href="" class="about-button">Hire me</a></p> -->
  
  <h4>Version</h4>
  <p>v22.05.19 b30</p>

</Dialog>

<script>

import LaserPowerUp from "./LaserPowerUp.svelte";
import Dialog from "./Dialog.svelte";

import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

import { onMount } from "svelte";
onMount(async () => {
  if ( localStorage.getItem("aboutOpen") == null ) {
    showAbout = true;
    dispatch("paused", {paused: true});
  } else {
    showAbout = false;
    dispatch("paused", {paused: false});
  }
});

export let laserPowerUp = 100;
export let score = 0;

let showAbout = false;
let aboutButtons = [ {text: "Done", callback: "close", disabled: false, }, ];

function aboutHelper() {
  showAbout = true;
  dispatch("paused", {paused: true});
}

function closeHelper() {
  showAbout = false;
  localStorage.aboutOpen = false;
  dispatch("paused", {paused: false});
}

</script>