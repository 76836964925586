const Types = require("./Types.js");

// console.log("Types", Types);

let assoc = [];
assoc["diggy"] = {w: 106, h: 64, t: 0};

// walking right
assoc["diggy1-1"] = {w: 106, h: 64, t: 0};
assoc["diggy1-2"] = {w: 106, h: 64, t: 0};
assoc["diggy1-3"] = {w: 106, h: 64, t: 0};
assoc["diggy1-4"] = {w: 106, h: 64, t: 0};
assoc["diggy1-5"] = {w: 106, h: 64, t: 0};

// walking left
assoc["diggy2-1"] = {w: 106, h: 64, t: 0};
assoc["diggy2-2"] = {w: 106, h: 64, t: 0};
assoc["diggy2-3"] = {w: 106, h: 64, t: 0};
assoc["diggy2-4"] = {w: 106, h: 64, t: 0};
assoc["diggy2-5"] = {w: 106, h: 64, t: 0};

// running right
assoc["diggy3-1"] = {w: 106, h: 64, t: 0};
assoc["diggy3-2"] = {w: 106, h: 64, t: 0};
assoc["diggy3-3"] = {w: 106, h: 64, t: 0};
assoc["diggy3-4"] = {w: 106, h: 64, t: 0};

// running left
assoc["diggy4-1"] = {w: 106, h: 64, t: 0};
assoc["diggy4-2"] = {w: 106, h: 64, t: 0};
assoc["diggy4-3"] = {w: 106, h: 64, t: 0};
assoc["diggy4-4"] = {w: 106, h: 64, t: 0};


// jumping right
assoc["diggy10-1"] = {w: 106, h: 76, t: 0};
assoc["diggy10-2"] = {w: 106, h: 71, t: 0};
assoc["diggy10-3"] = {w: 106, h: 53, t: 0};

// jumping left
assoc["diggy11-1"] = {w: 106, h: 76, t: 0};
assoc["diggy11-2"] = {w: 106, h: 71, t: 0};
assoc["diggy11-3"] = {w: 106, h: 53, t: 0};

// surfaces

// baddies
assoc["squirrel"] = {w: 241, h: 136, t: Types["squirrel"]};

// allies

// props
assoc["cloud1"] = {w: 241, h: 160, t: Types["cloud1"]};
assoc["cloud2"] = {w: 241, h: 124, t: Types["cloud2"]};
assoc["tree1"] = {w: 357, h: 566, t: Types["tree1"]};
assoc["grass1"] = {w: 118, h: 62, t: Types["grass1"]};
assoc["flower1"] = {w: 9, h: 19, t: Types["flower1"]};
assoc["flower2"] = {w: 9, h: 20, t: Types["flower2"]};
assoc["flower3"] = {w: 4, h: 18, t: Types["flower3"]};
assoc["sign0"] = {w: 85, h: 130, t: Types["sign0"]};
assoc["sign1"] = {w: 85, h: 130, t: Types["sign1"]};

// treasure - c for center, is a precalced value
assoc["bone1"] = {w: 18, h: 20, t: Types["bone1"], c: 18 / 2 }; // regular bone
assoc["bone2"] = {w: 18, h: 20, t: Types["bone2"], c: 18 / 2 }; // gold bone
assoc["emerald1"] = {w: 18, h: 18, t: Types["emerald1"], c: 18 / 2 };
assoc["ruby1"] = {w: 22, h: 18, t: Types["ruby1"], c: 22 / 2 };
assoc["gold1"] = {w: 22, h: 19, t: Types["gold1"], c: 22 / 2 };



// associates image assets with their width, height, and type
module.exports.now = function(assets) {
  for (let i in assets) {
    // console.log(i);
    // console.log(assets[i], assoc[i]);
    assets[i].w = assoc[i].w;
    assets[i].h = assoc[i].h;
    assets[i].t = assoc[i].t;
    if ("c" in assoc[i]) assets[i].c = assoc[i].c;
  }
}