<style>
main{
	margin: 0;
}
  
</style>

<main>
  {#if assetsLoaded == true}
    <Header score="{score}" laserPowerUp="{laserPowerUp}" on:paused="{ (e) => {paused = e.detail.paused} }"></Header>
    <Game assets="{imageAssets}" soundAssets="{soundAssets}" bind:score="{score}"></Game>
  {:else}
    <Assets assets="{imageAssets}" soundAssets="{soundAssets}" on:loaded="{assetsNowLoaded}"></Assets>
  {/if}
</main>

<script>

import Assets from "./Assets.svelte";
import AssetAssoc from "./AssetAssoc.js";

import Header from "./Header.svelte";
import Game from "./Game.svelte";

let assetsLoaded = false;

let score = 0;
let laserPowerUp = 0;
let paused = true;

let imageAssets = [];
let soundAssets = [];

// debug purposes only
// resets the generated game board on every page load
localStorage.removeItem("window");
localStorage.removeItem("world");
// debug purposes only

function assetsNowLoaded() {
  AssetAssoc.now(imageAssets);
  assetsLoaded = true;
}

</script>
