module.exports.grid = function(game, enabled) {
  if (enabled == null || enabled == false) return;
  
  game.ctx.strokeStyle = "#000";
  game.ctx.lineWidth = 1;
  
  for (let tx = 0; tx < game.tiles.width; tx++ ) {
    for (let ty = 0; ty < game.tiles.height; ty++ ) {
      let fx = game.tiles.startX + (tx * game.tileSizeX);
      let fy = game.tiles.startY + (ty * game.tileSizeY);
      game.ctx.strokeRect( fx + 0.5, fy + 0.5, game.tileSizeX, game.tileSizeY);
    }
  }
  
  game.ctx.strokeStyle = "000";
  game.ctx.fillStyle = "red";
  game.ctx.fillRect( (game.canvas.width / 2 - game.tileSizeX / 2) + 0.5, (game.canvas.height / 2 - game.tileSizeY / 2) + 0.5, game.tileSizeX, game.tileSizeY);
  
}


module.exports.centerPoint = function(game) {
  game.ctx.fillStyle = "black";
  game.ctx.fillRect( game.center.x - 3.5, game.center.y - 3.5, 5, 5 ); 
  game.ctx.fillStyle = "pink";
  game.ctx.fillRect( game.center.x - 2.5, game.center.y - 2.5, 4, 4 ); 
}

module.exports.location = function(game) {
  game.ctx.font = "18px Open Sans";
  game.ctx.fillStyle = "black";
  game.ctx.fillText("(" + Math.round(game.character.x) + ", " + Math.round(game.character.y) + ")", 2, 20);
}

module.exports.version = function(game) {
  game.ctx.font = "20px Open Sans";
  game.ctx.fillStyle = "#0009";
  game.ctx.fillText("v22.05.19 b30 ALPHA", 2, game.canvas.height - 15);
}

module.exports.fps = function(game, fps) {
  game.ctx.font = "18px Open Sans";
  game.ctx.fillStyle = "black";
  game.ctx.fillText("(fps: "+fps+")", 2, 40);
}

